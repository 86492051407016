const CommentUserIcon = ({authorRole}) => {

  let icon;
  switch (authorRole) {
    case 'admin': icon = '👑'; break;
    default: icon = '😀'; break;
  }

  return (
    <div style={{display: 'flex', placeItems: 'center'}}>
      <div className="hearing-comment-user-icon">
        <div>
          <span>{icon}</span>
        </div>
      </div>
    </div>
  )
}

export default CommentUserIcon;