/* eslint-disable react/no-did-mount-set-state */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Alert, Button, Checkbox, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { v1 as uuid } from 'uuid';
import Icon from '../utils/Icon';
import { getImageAsBase64Promise } from '../utils/hearing';
import { localizedNotifyError } from '../utils/notify';
import { getSectionCommentingErrorMessage } from '../utils/section';
// eslint-disable-next-line import/no-unresolved
import urls from '@city-assets/urls.json';
// eslint-disable-next-line import/no-unresolved
import localization from '@city-i18n/localization.json';
import leafletMarkerIconUrl from '../../assets/images/leaflet/marker-icon.png';
import Leaflet, { LatLng } from 'leaflet';
import leafletMarkerShadowUrl from '../../assets/images/leaflet/marker-shadow.png';
import leafletMarkerRetinaIconUrl from '../../assets/images/leaflet/marker-icon-2x.png';
import DigiraatiEmojiInput from './EmojiInput';
import Sticker from './Hearing/sortable-comment-list/comment-list/comment/Sticker';
import getMessage from '../utils/getMessage';
import PinUnpinButton from './PinUnpinButton';


Leaflet.Marker.prototype.options.icon = new Leaflet.Icon({
  iconUrl: leafletMarkerIconUrl,
  shadowUrl: leafletMarkerShadowUrl,
  iconRetinaUrl: leafletMarkerRetinaIconUrl,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
});

const BaseCommentForm = ({
  defaultNickname = '',
  editedText,
  cancelEditing,
  collapseForm,
  onOverrideCollapse = () => {},
  hasDelayToSaveType = false,
  setEditing,
  nicknamePlaceholder,
  user,
  hideNameForm,
  canComment, 
  section,
  hearingGeojson,
  isHighContrast, 
  language,
  isReply = false, 
  allowFiles = true,
  onPostComment,
}) =>  {
  const id = Math.random().toString(36).slice(2, 7);
  const [storedTimeout, setStoredTimeout] = useState(null)

  const [collapsed, setCollapsed] = useState(true);
  const [commentText, setCommentText] = useState('');
  const [nickname, setNickname] = useState(defaultNickname || '');
  const [imageTooBig, setImageTooBig] = useState(false);
  const [images, setImages] = useState([]);
  const [pinned, setPinned] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const [hideName, setHideName] = useState(false);
  const [sticker, setSticker] = useState(null);

  const [formToolsOpen, setFormToolsOpen] = useState(null)

  // The shady ones
  const [geojson, setGeojson] = useState({});
  const [mapCommentText, setMapCommentText] = useState('')

  const imagesRef = useRef();

  useEffect(() => {
    if (isUserAdmin()) {
      setNickname(defaultNickname);
    }
    if (!!editedText && editedText !== commentText) {
      setCommentText(editedText);
    }
  }, [])

  useEffect(() => {
    if (collapseForm) {
      clearCommentText();
      toggle();
    }
  }, [collapseForm])

  const clearState = () => {
    setCollapsed(!collapsed);
    setCommentText('');
    setNickname(defaultNickname || '');
    setImageTooBig(false);
    setImages([]);
    setPinned(false);
    setShowAlert(true);
    setHideName(false);
    setMapCommentText('');
    setSticker(null);
    setFormToolsOpen(null)
  }

  const toggle = () => {
    if (canComment) {
      cancelEditing();
      clearState();
      if (onOverrideCollapse instanceof Function) {
        onOverrideCollapse();
      }
    } else {
      localizedNotifyError(getSectionCommentingErrorMessage(section));
    }
  }

  const handleTextChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setCommentText(event.target.value);
    if (hasDelayToSaveType) {
      const COMMENT_POLL_INTERVAL_IN_MS = 1000;
      clearTimeout(storedTimeout);
      const time = setTimeout(() => {
        if (commentText !== editedText) {
          setEditing(commentText);
        }
      }, COMMENT_POLL_INTERVAL_IN_MS);
      setStoredTimeout(time);
    } else {
      if (event.target.value !== editedText) {
        setEditing(event.target.value);
      }
    }
  }

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
  }

  const clearCommentText = () => {
    setCommentText('');
  }

  const submitComment = () => {
    let _pluginData = undefined; // getPluginData();
    let _nickname =
      nickname === ''
        ? nicknamePlaceholder
        : nickname;
    let _commentText =
      commentText === null ? '' : commentText;
    let _geojson = geojson;
    let _label = null;
    let _images = images;
    let _pinned = pinned;
    let _mapCommentText = mapCommentText;
    let _sticker = sticker;
    onPostComment(
      _commentText,
      _nickname,
      _pluginData,
      _geojson,
      _label,
      _images,
      _pinned,
      _mapCommentText,
      _sticker,
    );
    clearState();
  }

  const handleChange = (event) => {
    const imagePromisesArray = [];
    const _images = [];
    isImageTooBig(event.target.files);
    const imagesElement = imagesRef.current;

    for (let _i = 0; _i < imagesElement.files.length; _i += 1) {
      imagePromisesArray.push(
        getImageAsBase64Promise(imagesElement.files[_i]),
      );
    }

    Promise.all(imagePromisesArray).then(arrayOfResults => {
      for (let _i = 0; _i < imagesElement.files.length; _i += 1) {
        const imageObject = { title: 'Title', caption: 'Caption' };

        imageObject.image = arrayOfResults[_i];
        _images.push(imageObject);
      }

      setImages(_images);
    });
  }

  /**
   * Determines whether the logged in user is admin or not.
   * The array in users with key adminOrganizations should be of length > 0
   */
  const isUserAdmin = () =>
    user &&
    Array.isArray(user.adminOrganizations) &&
    user.adminOrganizations.length > 0;


  /**
   * When user type is admin, an alert is shown, use this method to close the alert.
   */
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  /**
   * When logged in as admin, user may chose to hide their identity.
   */
  const handleToggleHideName = () => {
    setNickname(!hideName ? getMessage('employee') : defaultNickname);
    setHideName(!hideName);
  };

  /**
   * Toggle the pinning of comment
   */
  const handleTogglePin = () => {
    setPinned(!pinned);
  };

  const isImageTooBig = (images) => {
    // eslint-disable-line class-methods-use-this
    let isImageTooBig = false;
    Array.from(images).forEach(image => {
      if (image.size > 1000000) {
        isImageTooBig = true;
      }
    });
    setImageTooBig(isImageTooBig);
  }

  /**
   * When admin user is posting a comment, we will show a closeable warning.
   */
  const renderAdminWarning = () => (
    <Alert bsStyle="warning">
      <div className="comment-form__comment-alert">
        <div className="comment-form__comment-alert__alert-icon">
          <Icon name="info-circle" size="lg" />
        </div>
        <span className="comment-form__comment-alert__alert-message">
          <FormattedMessage id="adminCommentMessage" />
        </span>
        <div className="comment-form__comment-alert__alert-close">
          <Icon name="close" onClick={handleCloseAlert} />
        </div>
      </div>
    </Alert>
  );

  /**
   * Render the checkbox to hide user name and identitiy for admin user.
   */
  const renderHideNameOption = () => (
    <Checkbox
      checked={hideName}
      key={uuid()}
      onChange={handleToggleHideName}
    >
      <FormattedMessage id="hideName" />
    </Checkbox>
  );

  /**
   * If an admin type of user is posting comment, the form is slightly different.
   * @returns {JSX<Component>}
   */
  const renderNameFormForUser = () => {
    if (hideNameForm) {
      return <></>;
    }
    const isAdminUser = isUserAdmin();
    const headingId = isAdminUser ? 'nameAndOrganization' : 'nickname';
    const organization = isUserAdmin() && user.adminOrganizations[0];

    return (
      <div className="name-and-organization">
        <label htmlFor="commentNickname" className="h4">
          <FormattedMessage id={headingId} />
        </label>
        {isAdminUser && showAlert && renderAdminWarning()}
        {isAdminUser && renderHideNameOption()}
        {isAdminUser && (
          <div className="comment-form__group-admin">
            <dl>
              <dt>{getMessage('nickname')}</dt>
              <dd>{nickname}</dd>
              <dt>{getMessage('organization')}</dt>
              <dd>{organization || ''}</dd>
            </dl>
          </div>
        )}
      </div>
    );
  };

  const getId = (prefix) => {
    return prefix + '-' + id;
  }

  const addEmojiToCommentSelectionStart = (emoji) => {
    const cursorPosition = document.getElementById(getId('commentTextField')).selectionStart;
    const textBefore = commentText.slice(0, cursorPosition);
    const textAfter = commentText.slice(cursorPosition);
    setCommentText(textBefore + emoji + textAfter);
    document
      .getElementById(getId('commentTextField'))
      .setSelectionRange(cursorPosition + 1, cursorPosition + 1);
  }

  const setCommentSticker = (stickerId) => {
    setSticker(stickerId);
  }

  const setOrToggleFormTools = (tools) => {
    if (formToolsOpen === tools) {
      setFormToolsOpen(null); // toggle off
    } else {
      setFormToolsOpen(tools); // open
    }
  }

  return (
    <div className="comment-form">
      <form>
        <div className="comment-form__heading-container">
          {!!formToolsOpen && <div className="comment-form__heading-container__heading-popup">
            <div style={{display: 'grid', gridTemplateColumns:'1fr'}}>
              <button type="button" className="digi-button" onClick={() => setFormToolsOpen(null)}>&times;</button>
            </div>
            <div style={{overflowY: 'auto'}}>
              { (formToolsOpen === 'emoji') &&
              <DigiraatiEmojiInput
                emojiSelectionHandler={addEmojiToCommentSelectionStart}
                stickerSelectionHandler={setCommentSticker}
                selectedSticker={sticker}
              />
              }
              { (formToolsOpen === 'files') && <>
                <div className="comment-form__selected-images">
                  {imageTooBig ? (
                    <div className="comment-form__image-too-big">
                      <FormattedMessage id="imageSizeError" />
                    </div>
                  ) : (
                    images.map((image, key) => (
                      <img
                        style={{ marginRight: 10 }}
                        alt=""
                        src={image.image}
                        width={image.width < 100 ? image.width : 100}
                        height={image.height < 100 ? image.width : 100}
                        key={key + Math.random()} //eslint-disable-line
                      />
                    ))
                  )}
                </div>
                <FormGroup className="comment-form__file">
                  <ControlLabel>
                    <FormattedMessage id="add_images" />
                  </ControlLabel>
                  <div className="comment-form__select-file">
                    <input
                      type="file"
                      ref={imagesRef}
                      id={getId('fileInput')}
                      multiple
                      className="custom-file-input"
                      onChange={event => handleChange(event)}
                    />
                    <label className="digi-button btn btn-sm" htmlFor={getId('fileInput')}>
                      <FormattedMessage id="choose_images" />
                    </label>
                  </div>
                  <span style={{ fontSize: 13, marginTop: 20 }}>
                  <FormattedMessage id="multipleImages" />
                </span>
                </FormGroup>
              </>}
              { (formToolsOpen === 'nameOrg') &&
                renderNameFormForUser()
              }
              </div>
          </div> }
          <div className="comment-form__heading-container__title">
            <label htmlFor="commentTextField" className="h4">
              <FormattedMessage id={isReply ? "writeReply" : "writeBaseComment"} />
            </label>
            
            <button type='button' className='digi-button btn' onClick={toggle}>
                <FormattedMessage id="cancel" />
              </button>
          </div>
          {isUserAdmin() && !isReply && (
            <div className="comment-form__heading-container__pin">
              <PinUnpinButton isPinned={pinned} handleTogglePin={handleTogglePin} />
            </div>
          )}
        </div>
        {
          sticker &&
          <div>
            <Sticker stickerId={sticker} />
            <Button onClick={() => setCommentSticker(null)}>
              <FormattedMessage id="delete" />
            </Button>
          </div>
        }
        <div className='input-container'>
          <div className='textarea-container'>
            <FormControl
              autoFocus
              className="comment-textarea"
              componentClass="textarea"
              value={commentText}
              onChange={handleTextChange}
              id={getId('commentTextField')}
            />
          </div>
          <div className="buttons-container">
            <div className="left-buttons">
              <button type='button' className='digi-button btn' onClick={() => setOrToggleFormTools('emoji')}>😀</button>
              {allowFiles && 
              <button type='button' 
                className='digi-button btn' 
                onClick={() => setOrToggleFormTools('files')}
                style={{position: "relative"}}
                ><Icon name="fa-solid fa-paperclip" />
                { (images.length > 0) && <div style={{position: "absolute", bottom:0, right: 0}}>{images.length}</div> }
              </button>}
              {isUserAdmin() && 
                <button type='button' 
                className='digi-button btn' 
                onClick={() => setOrToggleFormTools('nameOrg')}
                style={{position: "relative"}}
                ><FormattedMessage id='nameAndOrganization' />
                { (images.length > 0) && <div style={{position: "absolute", bottom:0, right: 0}}>{images.length}</div> }
              </button> }

            </div>
            <div className="right-buttons">
              <button type='button' className='digi-button btn'
                disabled={!commentText || imageTooBig}
                onClick={submitComment}
                title={getMessage('submit')}
              ><Icon name="fa-solid fa-send"/></button>
            </div>
          </div>
          
        </div>
      </form>
    </div>
  );
  
}

BaseCommentForm.propTypes = {
  canComment: PropTypes.bool,
  onPostComment: PropTypes.func,
  onOverrideCollapse: PropTypes.func,
  intl: intlShape,
  collapseForm: PropTypes.bool,
  defaultNickname: PropTypes.string,
  nicknamePlaceholder: PropTypes.string,
  section: PropTypes.object,
  language: PropTypes.string,
  user: PropTypes.object,
  isReply: PropTypes.bool,
  isHighContrast: PropTypes.bool,
  hearingGeojson: PropTypes.object,
  allowFiles: PropTypes.bool,
  hideNameForm: PropTypes.bool,
  setEditing: PropTypes.func,
  cancelEditing: PropTypes.func,
  editedText: PropTypes.string,
  hasDelayToSaveType: PropTypes.bool,
};

const mapStateToProps = state => ({
  isHighContrast: state.accessibility.isHighContrast,
});
const WrappedBaseCommentForm = connect(
  mapStateToProps,
  null,
)(injectIntl(BaseCommentForm));
export default WrappedBaseCommentForm;
