import { createAction } from 'redux-actions';
import api from '../../api';
import { localizedNotifyError, localizedNotifySuccess } from '../../utils/notify';
import merge from 'lodash/merge';
import parse from 'url-parse';
import Raven from 'raven-js';
import { push } from 'react-router-redux';
import { getDateTime } from '../../components/SocialBar/utils';
import { removeIsEditingComment } from './commentEditorActions';
import getMessage from '../../utils/getMessage';

export const setLanguage = createAction('setLanguage');
export const setHeadless = createAction('setHeadless');

// Declaring actions as JSON object for consistency
export const MainActions = {
  BEGIN_FETCH_SUB_COMMENTS: 'beginFetchSubComments',
  SUB_COMMENTS_FETCHED: 'subCommentsFetched',
};

function checkResponseStatus(response) {
  if (response.status >= 400) {
    const err = new Error(getMessage('BadResponse'));
    err.response = response;
    response.json().then(jsonResponse => {
      Raven.captureException(jsonResponse, {
        extra: {
          url: response.url,
          status: response.status,
        },
      });
    });
    throw err;
  }
}

export function getResponseJSON(response) {
  checkResponseStatus(response);
  if (response.status === 304) {
    return { status_code: response.status };
  }
  return response.json();
}

export function requestErrorHandler() {
  return err => {
    Raven.captureException(err);
    if (err?.detail === 'Invalid token.' || err?.status == 401 || err?.response?.status === 401) {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
        localStorage.removeItem('lastApiCall')
        localizedNotifyError('loginExpired');
        window.location.href = '/auth/login';
      }
    } else {
      localizedNotifyError(err.message);
    }
  };
}

export const postCommentErrorHandler = () => {
  return err => {
    Raven.captureException(err);
    if (err.response.status === 403) {
      localizedNotifyError('loginToComment');
    } else {
      localizedNotifyError(err.message);
    }
  };
};

export const voteCommentErrorHandler = () => {
  return err => {
    Raven.captureException(err);
    if (err.response.status === 403) {
      localizedNotifyError('loginToVoteComment');
    } else {
      localizedNotifyError(err.message);
    }
  };
};

export function fetchInitialHearingList(listId, endpoint, params) {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchHearingList')({
      listId,
      params,
    });
    dispatch(fetchAction);

    // make sure the results will get paginated
    const paramsWithLimit = merge({ limit: 10 }, params);

    return api
      .get(getState(), endpoint, paramsWithLimit)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveHearingList')({ listId, data }));
      })
      .catch(requestErrorHandler(dispatch, fetchAction));
  };
}

export function fetchHearingList(listId, endpoint, params) {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchHearingList')({
      listId,
      params,
    });
    dispatch(fetchAction);

    // make sure the results won't get paginated
    const paramsWithLimit = merge({ limit: 99998 }, params);

    return api
      .get(getState(), endpoint, paramsWithLimit)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveHearingList')({ listId, data }));
      })
      .catch(requestErrorHandler());
  };
}

export function fetchProjects() {
  return (dispatch, getState) => {
    const fetchAction = createAction('fetchProjects')();
    dispatch(fetchAction);
    return api
      .get(getState(), 'v1/project')
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveProjects')({ data }));
      })
      .catch(() => {
        dispatch(createAction('receiveProjectsError')());
        requestErrorHandler();
      });
  };
}

export const fetchMoreHearings = listId => {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchHearingList')({ listId });
    dispatch(fetchAction);

    const url = parse(getState().hearingLists[listId].next, true);

    return api
      .get(getState(), 'v1/hearing/', url.query)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveMoreHearings')({ listId, data }));
      })
      .catch(requestErrorHandler(dispatch, fetchAction));
  };
};

export function fetchLabels() {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchLabels');
    dispatch(fetchAction);

    return api
      .getAllFromEndpoint(getState(), '/v1/label/')
      .then(data => {
        dispatch(createAction('receiveLabels')({ data }));
      })
      .catch(requestErrorHandler());
  };
}

export function fetchHearing(hearingSlug, previewKey = null) {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchHearing')({ hearingSlug });
    dispatch(fetchAction);
    const url = 'v1/hearing/' + hearingSlug + '/';
    const params = previewKey ? { preview: previewKey } : {};
    return api
      .get(getState(), url, params)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveHearing')({ hearingSlug, data }));
      })
      .catch(() => {
        dispatch(createAction('receiveHearingError')({ hearingSlug }));
        requestErrorHandler();
      });
    // FIXME: Somehow .catch catches errors also from components' render methods
  };
}

export function fetchHearingToUpdateCommentNum(hearingSlug, sectionId) {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchHearing')({ hearingSlug });
    dispatch(fetchAction);
    const url = 'v1/hearing/' + hearingSlug + '/';
    const params = {};
    return api
      .get(getState(), url, params)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('updateHearingCommentCount')({ hearingSlug, sectionId, data }));
      })
      .catch(() => {
        dispatch(createAction('receiveHearingError')({ hearingSlug }));
        requestErrorHandler();
      });
    // FIXME: Somehow .catch catches errors also from components' render methods
  };
}

export function followHearing(hearingSlug) {
  return (dispatch, getState) => {
    const fetchAction = createAction('beginFollowHearing')({ hearingSlug });
    dispatch(fetchAction);
    const url = 'v1/hearing/' + hearingSlug + '/follow';
    return api
      .post(getState(), url)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveFollowHearing')({ hearingSlug, data }));
        dispatch(fetchHearing(hearingSlug));
        localizedNotifySuccess('followingHearing');
      })
      .catch(requestErrorHandler());
  };
}

export function fetchSectionComments(
  hearingSlug,
  sectionId,
  ordering = '-n_votes',
  cleanFetch = true,
) {
  return async (dispatch, getState) => {
    const fetchAction = createAction('beginFetchSectionComments')({
      sectionId,
      ordering,
      cleanFetch,
    });
    dispatch(fetchAction);
    const url = 'v1/comment/';
    const params = {
      section: sectionId,
      include: 'plugin_data',
      limit: 100,
      comment: 'null',
      ...(ordering && { ordering }),
    };

    const promises = [
      api
        .get(getState(), url, { ...params, pinned: false })
        .then(getResponseJSON),
      api
        .get(getState(), url, { ...params, pinned: true })
        .then(getResponseJSON),
    ];

    const [unpinnedResponse, pinnedResponse] = await Promise.all(promises);
    const mergedResults = unpinnedResponse;

    if (pinnedResponse.results.length > 0) {
      mergedResults.count += pinnedResponse.count;
      mergedResults.results = [
        ...pinnedResponse.results,
        ...mergedResults.results,
      ];
    }

    return dispatch(
      createAction('receiveSectionComments')({
        sectionId,
        data: mergedResults,
      }),
      dispatch(fetchHearing(hearingSlug)),
    );
  };
}

/**
 * Get a list of subcomments for a single comment.
 * @param {Number} commentId - is of the parent comment.
 * @param {String} sectionId - id of the section the comment belongs to.
 */
export const getCommentSubComments = (commentId, sectionCommentId, sectionId, jumpTo) => {
  return (dispatch, getState) => {
    const fetchAction = createAction(MainActions.BEGIN_FETCH_SUB_COMMENTS)({
      sectionId,
      sectionCommentId,
      commentId,
    });
    dispatch(fetchAction);
    const url = 'v1/comment/';
    const params = {
      section: sectionId,
      include: 'plugin_data',
      limit: 100,
      comment: commentId,
      ordering: 'created_at',
    };
    return api
      .get(getState(), url, params)
      .then(getResponseJSON)
      .then(data => {
        dispatch(
          createAction(MainActions.SUB_COMMENTS_FETCHED)({
            sectionId,
            sectionCommentId,
            commentId,
            data,
            jumpTo: jumpTo ?? commentId,
          }),
        );
      })
      .catch(requestErrorHandler());
  };
};


export const fetchFocusedCommentTree = (
  sectionId,
  sourceCommentId,
  hearingSlug,
) => {
  return (dispatch, getState) => {

    const params = { comment_id: sourceCommentId };
    const url = 'v1/hearing/' + hearingSlug + '/sections/' + sectionId + '/comments-tree';
    return api
      .get(getState(), url, params)
      .then(getResponseJSON)
      .then(async commentTreeList => {

        const subCommentsByParentIdMap = new Map();
        commentTreeList.forEach(c => {
          if (!subCommentsByParentIdMap.has(c.comment)) {
            subCommentsByParentIdMap.set(c.comment, []);
          }
          subCommentsByParentIdMap.get(c.comment).push(c);
        });
        /*
        Map will have structure:
        null:[second level sub comment list] -root/section lever comment and its sub comments
        id1: [third level sub comment list] -second level comment (next level after root/section level) and its sub comments
        etc.
        * */
        const sectionCommentId = commentTreeList.find(c => !c.comment)?.id;
        for (let [key, value] of subCommentsByParentIdMap) {
          // if key is not null -> this comment is not root/section comment, should set it sub comments
          // if key is null -> he is already in store, nothing need to do
          if (!!key) {
            const commentId = key;
            const jumpTo = sourceCommentId;
            const data = { results: value };
            await dispatch(
              createAction(MainActions.SUB_COMMENTS_FETCHED)({
                sectionId,
                sectionCommentId,
                commentId,
                data,
                jumpTo,
              }),
            );
          } else if (subCommentsByParentIdMap.size === 1) {
            // if key is null and map has only one comment -> just jump to root/section comment and do not upload it sub comments
            const jumpTo = sourceCommentId;
            await dispatch(createAction('setJumpTo')({ sectionId, jumpTo }));
          }
        }
      })
      .catch(requestErrorHandler());
  };
};

export function fetchMoreSectionComments(
  sectionId,
  ordering = '-n_votes',
  next,
) {
  const cleanFetch = false;

  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchSectionComments')({
      sectionId,
      ordering,
      cleanFetch,
    });
    dispatch(fetchAction);
    const url = parse(next, true);
    return api
      .get(getState(), 'v1/comment/', url.query)
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveSectionComments')({ sectionId, data }));
      })
      .catch(requestErrorHandler());
  };
}

export function getCommentIds(
  state,
  hearingId,
  sectionId,
  createdAtMin,
  ownComments,
) {
  const url =
    'v1/hearing/' + hearingId + '/sections/' + sectionId + '/comments-list';
  const createdAtMinParam = getDateTime(createdAtMin);
  return api
    .get(state, url, {
      include: 'plugin_data',
      created_at__gt: createdAtMinParam,
      own_comments: ownComments,
    })
    .then(getResponseJSON)
    .catch(requestErrorHandler());
}

export function fetchAllSectionComments(
  hearingSlug,
  sectionId,
  ordering = '-n_votes',
) {
  const cleanFetch = true;

  return (dispatch, getState) => {
    const fetchAction = createAction('beginFetchSectionComments')({
      sectionId,
      ordering,
      cleanFetch,
    });
    dispatch(fetchAction);
    const url =
      'v1/hearing/' + hearingSlug + '/sections/' + sectionId + '/comments';
    return api
      .get(getState(), url, { include: 'plugin_data', ordering })
      .then(getResponseJSON)
      .then(data => {
        dispatch(createAction('receiveSectionComments')({ sectionId, data }));
      })
      .then(() => dispatch(fetchHearing(hearingSlug))) // updates comment amount
      .catch(requestErrorHandler());
  };
}


export function postSectionComment(hearingSlug, sectionId, sectionCommentId, commentData = {}) {

  return (dispatch, getState) => {
    const fetchAction = createAction('postingComment')({
      hearingSlug,
      sectionId,
    });
    const jumpAction = createAction('setJumpTo')({ sectionId, jumpTo: null });
    dispatch(jumpAction, fetchAction);
    const url =
      '/v1/hearing/' + hearingSlug + '/sections/' + sectionId + '/comments/';
    let params = {
      content: commentData.text ? commentData.text : '',
      plugin_data: commentData.pluginData ? commentData.pluginData : null,
      authorization_code: commentData.authCode ? commentData.authCode : '',
      geojson: commentData.geojson ? commentData.geojson : null,
      label: commentData.label ? commentData.label : null,
      images: commentData.images ? commentData.images : [],
      answers: commentData.answers ? commentData.answers : [],
      pinned: commentData.pinned ? commentData.pinned : false,
      map_comment_text: commentData.mapCommentText
        ? commentData.mapCommentText
        : '',
      sticker: commentData.sticker ? commentData.sticker : null,
    };
    if (commentData.authorName) {
      params = Object.assign(params, { author_name: commentData.authorName });
    }
    if (commentData.comment) {
      params = { ...params, comment: commentData.comment };
    }

    return api
      .post(getState(), url, params)
      .then(getResponseJSON)
      .then(data => {
        if (commentData.comment && typeof commentData.comment !== 'undefined') {
          dispatch(
            getCommentSubComments(commentData.comment, sectionCommentId, sectionId, data.id),
          );
        } else {
          dispatch(
            createAction('postedComment')({ sectionId, jumpTo: data.id }),
          );
        }
        // we must update hearing comment count
        dispatch(fetchHearingToUpdateCommentNum(hearingSlug, sectionId));

        localizedNotifySuccess('commentReceived');
      })
      .catch(postCommentErrorHandler());
  };
}

export const getFinalStatementDraftComments = async (finalStatementId) => {
  const url = '/v1/statement-draft-comment';
  return await api.get({}, url, { 'finalstatement': finalStatementId })
    .then(getResponseJSON)
    .catch(requestErrorHandler());
};

export function postFinalStatementDraftComment(hearingSlug, sectionId, commentData = {}) {
  return (dispatch, getState) => {
    const url =
      '/v1/statement-draft-comment/';
    let params = {
      content: commentData.text ? commentData.text : '',
      finalstatement: commentData.finalstatement,
    };

    return api
      .post(getState(), url, params)
      .then(getResponseJSON)
      .then(data => {
        dispatch(
          createAction('postedComment')({ sectionId, jumpTo: data.id }),
        );
        localizedNotifySuccess('commentReceived');
      })
      .catch(postCommentErrorHandler());
  };
}

export function editFinalStatementDraftComment(
  commentId,
  commentData = {},
) {
  return (_dispatch, getState) => {
    const url = `/v1/statement-draft-comment/${commentId}`;
    const params = {
      content: commentData.text,
    };

    return api
      .put(getState(), url, params)
      .then(getResponseJSON)
      .then(responseJSON => {
        localizedNotifySuccess('commentEdited');
      })
      .catch(requestErrorHandler());
  };
}

export function hideFinalStatementDraftComment(commentId) {
  return (_dispatch, getState) => {
    const url = `/v1/statement-draft-comment/${commentId}/hide`;

    return api
      .patch(getState(), url)
      .then(checkResponseStatus)
      .then(() => {
        localizedNotifySuccess('commentUnpublished');
      })
      .catch(requestErrorHandler());
  };
}

export function unhideFinalStatementDraftComment(commentId) {
  return (_dispatch, getState) => {
    const url = `/v1/statement-draft-comment/${commentId}/unhide`;

    return api
      .patch(getState(), url)
      .then(checkResponseStatus)
      .then(() => {
        localizedNotifySuccess('commentPublished');
      })
      .catch(requestErrorHandler());
  };
}

export function deleteFinalStatementDraftComment(commentId) {
  return (_dispatch, getState) => {
    const url = `/v1/statement-draft-comment/${commentId}`;

    return api
      .apiDelete(getState(), url)
      .then(() => {
        localizedNotifySuccess('commentDeleted');
      })
      .catch(requestErrorHandler());
  };
}

export function editSectionComment(
  hearingSlug,
  sectionId,
  commentId,
  sectionCommentId,
  commentData = {},
) {
  return (dispatch, getState) => {
    const fetchAction = createAction('postingComment')({
      hearingSlug,
      sectionId,
    });
    const jumpAction = createAction('setJumpTo')({ sectionId, jumpTo: null });
    dispatch(jumpAction, fetchAction);
    const url =
      '/v1/hearing/' +
      hearingSlug +
      '/sections/' +
      sectionId +
      '/comments/' +
      commentId;
    const params = commentData;

    return api
      .put(getState(), url, params)
      .then(getResponseJSON)
      .then(responseJSON => {
        dispatch(
          createAction('editedComment')({ sectionId, sectionCommentId, commentData: responseJSON }),
        );
        dispatch(removeIsEditingComment(commentId, sectionId));
        dispatch(fetchHearing(hearingSlug));
        localizedNotifySuccess('commentEdited');
      })
      .catch(requestErrorHandler());
  };
}

export function publishSectionComment(hearingSlug, sectionId, commentId) {
  return (dispatch, getState) => {
    const fetchAction = createAction('postingComment')({
      hearingSlug,
      sectionId,
    });
    dispatch(fetchAction);
    const url =
      '/v1/hearing/' +
      hearingSlug +
      '/sections/' +
      sectionId +
      '/comments/' +
      commentId +
      '/publish'
    ;

    return api
      .patch(getState(), url)
      .then(() => {
        dispatch(createAction('postedComment')({ sectionId }));
        // we must update hearing comment count
        dispatch(fetchHearing(hearingSlug));
        localizedNotifySuccess('commentPublished');
      })
      .catch(requestErrorHandler());
  };
}

export function unPublishSectionComment(hearingSlug, sectionId, commentId) {
  return (dispatch, getState) => {
    const fetchAction = createAction('postingComment')({
      hearingSlug,
      sectionId,
    });
    dispatch(fetchAction);
    const url =
      '/v1/hearing/' +
      hearingSlug +
      '/sections/' +
      sectionId +
      '/comments/' +
      commentId +
      '/unpublish'
    ;

    return api
      .patch(getState(), url)
      .then(() => {
        dispatch(createAction('postedComment')({ sectionId }));
        // we must update hearing comment count
        dispatch(fetchHearing(hearingSlug));
        localizedNotifySuccess('commentUnpublished');
      })
      .catch(requestErrorHandler());
  };
}

export function deleteSectionComment(hearingSlug, sectionId, commentId) {
  return (dispatch, getState) => {
    const fetchAction = createAction('postingComment')({
      hearingSlug,
      sectionId,
    });
    dispatch(fetchAction);
    const url =
      '/v1/hearing/' +
      hearingSlug +
      '/sections/' +
      sectionId +
      '/comments/' +
      commentId;

    return api
      .apiDelete(getState(), url)
      .then(() => {
        dispatch(createAction('postedComment')({ sectionId }));
        // we must update hearing comment count
        dispatch(fetchHearing(hearingSlug));
        localizedNotifySuccess('commentDeleted');
      })
      .catch(requestErrorHandler());
  };
}

export function cleanJumpTo(sectionId) {
  return (dispatch) => {
    const cleanAction = createAction('cleanJumpTo')({
      sectionId,
    });
    dispatch(cleanAction);
  };
}

export function postVote(commentId, hearingSlug, sectionId, sectionCommentId) {
  return (dispatch, getState) => {
    const fetchAction = createAction('postingCommentVote')({
      hearingSlug,
      sectionId,
    });
    const jumpAction = createAction('setJumpTo')({ sectionId, jumpTo: null });
    dispatch(jumpAction, fetchAction);
    const url =
      '/v1/hearing/' +
      hearingSlug +
      '/sections/' +
      sectionId +
      '/comments/' +
      commentId +
      '/vote';
    return api
      .post(getState(), url)
      .then(getResponseJSON)
      .then(data => {
        dispatch(
          createAction('postedCommentVote')({
            commentId,
            sectionId,
            sectionCommentId,
            n_votes: data.n_votes,
            voted_by_user: data.voted_by_user,
          }),
        );
        if (data.code === 'REMOVED') {
          localizedNotifySuccess('voteRemoved');
        } else {
          localizedNotifySuccess('voteReceived');
        }
      })
      .catch(voteCommentErrorHandler());
  };
}

export function deleteHearingDraft(hearingId, hearingSlug) {
  return (dispatch, getState) => {
    const fetchAction = createAction('deletingHearingDraft')({
      hearingId,
      hearingSlug,
    });
    dispatch(fetchAction);
    const url = '/v1/hearing/' + hearingSlug;
    return api
      .apiDelete(getState(), url)
      .then(getResponseJSON)
      .then(() => {
        dispatch(push('/hearings/list?lang=' + getState().language));
        dispatch(createAction('deletedHearingDraft')({ hearingSlug }));
        localizedNotifySuccess('draftDeleted');
      })
      .catch(requestErrorHandler());
  };
}

export function toggleContrast() {
  return dispatch => {
    const toggleContrastState = createAction('toggleContrastState')();
    dispatch(toggleContrastState);
  };
}
