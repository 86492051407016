import getMessage from "../../../../../../utils/getMessage";

const CommentPinnedIcon = () => {
  return (
    <div className="hearing-comment-pinned-container" 
      style={{backgroundImage:"url(/assets/images/icon-metro-pin.svg)"}}
      alt={getMessage("pinnedComment")}
      title={getMessage("pinnedComment")}>
      &nbsp;
    </div>
  )
}

export default CommentPinnedIcon;