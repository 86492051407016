import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import nl2br from "react-nl2br";

const CommentBody = ({data}) => {
  const [showShowMoreButton, setShowShowMoreButton] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const paragraph = useRef()


  useEffect(() => {
    const current = paragraph.current;
    if (current) {
      const BUFFER_PIXELS = 5;
      if (current.scrollHeight > current.parentElement.clientHeight+BUFFER_PIXELS) {
        setShowShowMoreButton(true);
      }
    }
  }, [])

  const commentParagraphClass = (data) => {
    const contentChars = [...data.content];
    if (contentChars.length < 4) {
      const allEmojis = contentChars.reduce(
        (prev, char) => (prev && /\p{Emoji}/u.test(char)), true,
      );
      if (allEmojis) {
        return 'emoji-comment';
      }
    }
    return '';
  };

  const handleShowMore = () => {
    setShowMore(true);
    setShowShowMoreButton(false);
  }

  if (data.deleted) {
    return (<div className="hearing-comment-body deleted-comment">
      <span><FormattedMessage id="deletedCommentContent" /></span>
    </div>)
  }
  return (
  <div className="hearing-comment-body">
    <div className={classNames([{
      "hearing-comment-body-short" : !showMore,
      "hearing-comment-body-show-more": showShowMoreButton,
    }])}>
      <p ref={paragraph} className={commentParagraphClass(data)}>{nl2br(data.content)}</p>
    </div>
    {showShowMoreButton && 
      <div>
        <button type="button" 
          onClick={handleShowMore}
          className="digi-button"
          ><FormattedMessage id="showMore" /></button>
      </div>
    }
  </div>)
}

export default CommentBody;