import PropTypes from 'prop-types';
import { FormattedMessage} from 'react-intl';
import React from 'react';
import CommentUserIcon from './Comment/CommentUserIcon';
import CommentAuthorAndDate from './Comment/CommentAuthorAndDate';
import CommentPinnedIcon from './Comment/CommentPinnedIcon';
import CommentNewReleaseIcon from './Comment/CommentNewReleaseIcon';

const CommentHeaderComponent = ({
                                  isOwnComment,
                                  isAuthorAdminUser,
                                  commentData,
                                  isNewComment,
                                }) => {


  

  return (
    <div className="hearing-comment-header">
      {!commentData.published &&
        <FormattedMessage id="unpublished" />
      }
      <div className="hearing-comment-publisher">
        <CommentUserIcon authorRole={isAuthorAdminUser ? 'admin' : 'user'} />
        <CommentAuthorAndDate 
          isOwnComment={isOwnComment}
          isAuthorAdminUser={isAuthorAdminUser}
          commentData={commentData}
        />
      </div>
      <div className="hearing-comment-header__space-between">
        {isNewComment && <CommentNewReleaseIcon/>}
        {commentData.pinned && <CommentPinnedIcon/>}
      </div>
    </div>
  );
};

CommentHeaderComponent.propTypes = {
  toggleEditor: PropTypes.func,
  handleDelete: PropTypes.func,

  isAdminUser: PropTypes.bool,
  isOwnComment: PropTypes.bool,
  commentData: PropTypes.object,
  isNewComment: PropTypes.bool,
  
  isRepliesOpen: PropTypes.bool,
  renderHideReplyButton: PropTypes.func,
  renderViewReplyButton: PropTypes.func,
};

export default CommentHeaderComponent;